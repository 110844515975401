
const strings = {
  SITE_NAME: 'HNP Cares',
  TAGLINE_1: 'Get free resources to grow your business and beat your competition.',
  TAGLINE_2: 'Get resources that will take you to your next business milestone.',
  GET_ACCESS: 'Get Access',
  WHO_ARE_WE: 'Who is HNP Cares?',
  WHO_ARE_WE_ANSWER: 'HNP Cares is a team of successful business owners, business consultants, growth advisors, and sales and marketing professionals. Our mission is to give you free resources, strategies, and events that will help you grow your business and beat your competition.',
  WHAT_WE_DO: 'What We Do',
  WHAT_WE_DO_1_TITLE: 'Free Online Networking Events',
  WHAT_WE_DO_1_TEXT: 'Network online with like-minded professionals and build strategic relationships with allies that will refer you business.',
  WHAT_WE_DO_2_TITLE: 'Free Online Resources',
  WHAT_WE_DO_2_TEXT: 'Free eBooks, guides, and checklists that will help your business leapfrog your competition.',
  WHAT_WE_DO_3_TITLE: 'Free Expert Strategies Tailored To Your Challenges',
  WHAT_WE_DO_3_TEXT: 'Receive expert strategies based off of the needs to your business.',
  RECOGNIZED_BY: 'Our Work Has Been Recognized By',
  COPYRIGHT: `${new Date().getFullYear()} HNP Cares. All Rights Reserved.`
};

export default strings;