import React from 'react';
import './App.css';
import strings from './strings.js';

function App() {
  return (
    <iframe 
      src='https://join.hnpabc.com/hnpcares-landing-page-1/' 
      style={{ height: '100vh', width: '100vw' }} 
      />
  );

  return (
    <div>
      <div>
        <h1>{ strings.SITE_NAME }</h1>
        <h3>{ strings.TAGLINE_1 }</h3>
        <button>{ strings.GET_ACCESS }</button>
      </div>
      <div>
        <h4>{ strings.WHO_ARE_WE }</h4>
        <p>{ strings.WHO_ARE_WE_ANSWER }</p>
      </div>
      <div>
        <h4>{ strings.WHAT_WE_DO }</h4>
        <div>
          <div>
            <h5>{ strings.WHAT_WE_DO_1_TITLE }</h5>
            <p>{ strings.WHAT_WE_DO_1_TEXT }</p>
          </div>
          <div>
            <h5>{ strings.WHAT_WE_DO_2_TITLE }</h5>
            <p>{ strings.WHAT_WE_DO_2_TEXT }</p>
          </div>
          <div>
            <h5>{ strings.WHAT_WE_DO_3_TITLE }</h5>
            <p>{ strings.WHAT_WE_DO_3_TEXT }</p>
          </div>
        </div>
      </div>
      <div>
        <h4>{ strings.RECOGNIZED_BY }</h4>
        <div>
          <img />
          <img />
          <img />
        </div>
      </div>
      <footer>
        <div>
          <h2>{ strings.TAGLINE_2 }</h2>
          <button>{ strings.GET_ACCESS }</button>
        </div>
        <p>{ strings.COPYRIGHT }</p>
      </footer>
    </div>
  );
}

export default App;